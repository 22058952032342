@import "~antd/dist/antd.css";

:root {
  --logo-height: 100px;
  --header-height: 100px;
  --aside-width: 200px;
  --footer-height: 40px;
  --menu-top-height: 100px;
  --menu-top-height-resize: 200px;

  --shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1), 0 2px 49px 0 rgba(107, 7, 7, 0.06);

  --darkGray: #464747;
  --lightGray: #f1f1f1;
  --borderCard: rgb(211, 120, 120);
  --borderColor: #8d8e8e;
  --subItem: #616262;
  --hoverColor: #f1f1f1;
  --lineGray: #c9c9c9;
  --orange: #ff8c2e;
  --activeField: #0073aa;
  --inactiveItem: #9ea3a8;
  --white: #ffffff;

  --sizeSubtitle: 20px;
  --sizeSmall: 15px;
  --sizeTitle: 35px;
  --sizeTitleResize: 30px;
  --sizeSmallVar: 0.9em
}

@font-face {
  font-family: "Myriad";
  src: url("./utils/fonts/myriad-pro-8.otf");
}

* {
  box-sizing: content-box;
  font-family: "Myriad", monospace;
}

/* *********************************************** RELATÓRIOS ****************************************** */
/* Header */
h1.title {
  font-size: var(--sizeTitle);
}

/* Seções */
div.section {
  margin: 0 50px 10px 0;
}

div.sectionTitle {
  background-color: white;
  padding: 15px;
  border: solid;
  border-width: 0.5px;
  border-color: var(--lineGray);
}

h3.item-title {
  font-size: var(--sizeSubtitle);
  margin: 0;
}
div.sectionContent {
  background-color: white;
  padding: 20px 15px 20px 15px;
  border: solid;
  border-color: var(--lineGray);
  border-width: 0.5px;
  border-top: none;
}

div.sectionContent textarea{
  height: 80px;
} 

div.sectionContent.larger-text-area textarea{
  height: 200px;
} 

div.sectionContent.error {
  border: solid;
  border-width: 1px;
  border-color: red;
}

div.sectionContent.full-border.error {
  border: solid;
  border-width: 1px;
  border-color: red;
}

/* Página de leitura de relatórios */
.reading div.sectionContent p {
  font-size: 16px;
  margin: 0;
}

/* Página de edição */

.ant-btn.ant-dropdown-trigger.option-report {
  width: 350px;
  display: flex;
  padding: 0 10px 0 10px;
  justify-content: space-between;
  align-items: center;
}

.ant-btn.ant-dropdown-trigger span {
  font-size: var(--sizeSmall);
  font-family: "Myriad";
}

.ant-btn.ant-dropdown-trigger.time {
  width: 150px;
  margin-bottom: 15px;
  display: flex;
  padding: 0 10px 0 10px;
  justify-content: space-between;
  align-items: center;
}

.checkbox {
  margin-bottom: 10px;
}

.checkbox span {
  font-size: var(--sizeSmall);
  font-family: "Myriad";
}

textarea.ant-input {
  width: 40%;
  font-size: var(--sizeSmall);
  font-family: "Myriad";
}

@media (max-width: 540px) {
  textarea.ant-input {
    width: 80%;
    }
}

/* *********************************************** BUSCA ****************************************** */

.ant-btn.acoes-massa {
  height: 40px;
  padding-bottom: 5px;
}

.table-cell {
  flex-direction: column;
  justify-content: center;
}

.ant-btn.ant-input-search-button {
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f1f1f1;
}

thead[class*="ant-table-thead"] th {
  background-color: white !important;
  color: var(--darkGray);
}

@media (min-width: 890px) {
  .col-lg-1.col-md-5.col-5,
  .pl-0.col-lg-4.col-md-10.col-11,
  .col-lg-2.col-md-11.col-11 {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 0;
  }
}

td img {
  width: 300px;
  color: var(--lineGray);
}

.ant-btn.ant-input-search-button {
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 1px;
}
@media (max-width: 540px) {
  .ant-btn.ant-input-search-button {
    width: 20%;
    }
}

/* .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  width: 95%;
}
@media (max-width: 540px) {
  .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    width: 80%;
    }
} */

.ant-select.ant-select-auto-complete.ant-select-single.ant-select-allow-clear.ant-select-show-search {
  width: 95%;
}
@media (max-width: 540px) {
  .ant-select.ant-select-auto-complete.ant-select-single.ant-select-allow-clear.ant-select-show-search {
    width: 80%;
    }
}

.ant-table-footer{
  display: flex;
  justify-content: center;
}

.table-title-date{
  display: flex;
  align-items: center;
}

.table-title-date p{
  margin-bottom: 0px;
  margin-left: 5px;
  color: var(--activeField);
  height: 1em;
  color: var(--activeField);
  font-size: var(--sizeSmallVar)  
}

/* .ant-select.ant-select-lg.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  width: 20%;
}

@media (max-width: 540px) {
  .ant-select.ant-select-lg.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    width: 80%;
  }
} */

/* *********************************************** MENSAGENS ****************************************** */
.messageTitle {
  font-size: var(--sizeSmall);
  font-weight: bolder;
}

.message-field {
  margin-right: 50px;
  border: solid;
  border-width: 0.5px;
  border-color: var(--lineGray);
}

.message-field textarea.ant-input {
  width: 100%;
  padding: 25px 15px 0 15px;
}

.submit {
  margin: 20px 0 50px 0;
}
/* *********************************************** SENHA ****************************************** */

/* Seções */
div.section.password {
  margin: 0 50px 20px 0;
  width: 60%;
}

div.sectionTitle.password {
  background-color: white;
  padding: 15px;
  border: solid;
  border-width: 0.5px;
  border-color: var(--lineGray);
  width: 60%;
}

div.sectionContent.password {
  background-color: white;
  padding: 20px 15px 0 15px;
  border: solid;
  border-color: var(--lineGray);
  border-width: 0.5px;
  border-top: none;
  width: 60%;
}

/* *********************************************** CADASTRO DE USUÁRIOS ****************************************** */

/* Observação */
.parents div.sectionContent p,
.tutor div.sectionContent p,
.student div.sectionContent p {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0;
}

.sectionContent.address {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 250px;
}

/* Página de atualização de informações */

.ant-tag {
  font-size: 14px !important;
  margin-bottom: 10px !important;
}

form.form-tutor-hours div.ant-form-item-label label {
  align-items: center;
  margin: 0px;
}

form.form-tutor-hours div.ant-form-item div.ant-form-item-control button {
  height: 22px;
  padding: 4px 11px 4px 11px;
}

form.form-tutor-hours
  div.ant-form-item
  div.ant-form-item-control
  div.totalHours {
  height: 22px;
  width: 50px;
  text-align: center;
}

form.form-tutor-hours
  div.ant-form-item
  div.ant-form-item-control
  div.totalHours
  p.content {
  font-size: var(--sizeSmall);
}

form.form-tutor-hours div.ant-form-item div.ant-form-item-control button span {
  padding: 2px 6px 2px 6px;
}

div.sectionContent.time {
  border-bottom: none;
}

div.sectionContent.time span svg {
  margin-bottom: 5px;
}

div.sectionContent.time-form .ant-picker.ant-picker-range {
  margin: 0 20px 0 40px;
  height: 34px;
}

@media (min-width: 540) {
  div.sectionContent.time-form .ant-picker.ant-picker-range {
    width: 200px !important;
  }
}

div.sectionContent.time-form .ant-btn {
  padding: 0 10px 0 10px;
}

div.sectionContent.time-form p {
  margin-top: 20px;
  font-size: 12px;
}

@media (max-width: 768px) {
  div.sectionContent.time-form {
    display: flex;
    flex-direction: column;
  }
  div.sectionContent.time-form .ant-picker.ant-picker-range {
    margin: 20px 0 20px 0;
  }

  div.sectionContent.time-form .ant-btn {
    height: 30px;
    width: 30px;
  }
}

/* *********************************************** AGENDA ****************************************** */

div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  margin-right: 5%;
  margin-bottom: 5%;
}

div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
  .fc-view-harness.fc-view-harness-active {
  height: 650px !important;
  background-color: #ffffff;
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
  background-color: var(--darkGray);
  color: #ffffff;
}

.fc .fc-button-primary {
  background-color: var(--darkGray);
  border-color: var(--darkGray);
}

.fc .fc-button-primary:hover {
  background-color: var(--darkGray);
  border-color: var(--darkGray);
}

.fc .fc-button-primary:disabled {
  background-color: var(--inactiveItem);
  border-color: var(--inactiveItem);
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--inactiveItem);
  border-color: var(--inactiveItem);
}

td.fc-daygrid-day.fc-day.fc-day-today {
  background-color: #e6ffff;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}

/* td.fc-timegrid-slot.fc-timegrid-slot-lane {
  background-color: #ffffff;
} */

td.fc-timegrid-col.fc-day.fc-day-today {
  background-color: #e6ffff;
}

div.ant-card-head-title {
  color: var(--darkGray);
}

div.ant-modal-footer button.ant-btn.ant-btn-primary {
  background-color: var(--orange);
  border-color: var(--orange);
}

div.ant-modal-confirm-btns button.ant-btn.ant-btn-primary {
  background-color: var(--orange);
  border-color: var(--orange);
}

div.ant-modal-confirm-btns button.ant-btn.ant-btn-primary span {
  color: var(--white);
}

div.ant-modal-confirm-body span.anticon.anticon-info-circle svg {
  color: var(--orange);
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: var(--darkGray);
}

div.ant-form-item-control-input-content .row.align-items-center .time-picker {
  margin-right: 15px;
}

div.ant-form-item-control-input-content
  .row.align-items-center
  .time-picker
  .ant-picker {
  width: 75px;
}

div.ant-modal-body span.ant-checkbox.ant-checkbox-checked > span {
  background-color: var(--orange);
  border-color: var(--orange);
}

div.ant-modal-body .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--orange);
}

div.ant-modal-body .ant-checkbox-checked::after {
  border-color: var(--orange);
}

div.ant-modal-body .ant-picker {
  width: 91px;
}

.fc-timegrid-now-indicator-arrow,
.fc-timegrid-now-indicator-line {
  border-color: var(--activeField) !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.fc-timegrid-now-indicator-line {
  border-color: var(--activeField) !important;
  border-width: 2px 0 0 !important;
}

button.modal-title-options {
  border: none;
  background-color: var(--white);
}

div.ant-modal-body div.modal-schedules-section {
  margin-bottom: 30px;
}

div.ant-modal-body div.modal-schedules-section p.modal-section-title {
  font-size: 16px;
}
div.ant-modal-body > div.modal-frequency-section.row > div.col-2 > p,
div.ant-modal-body > div.modal-frequency-section.row > div.col-3 > p {
  margin-bottom: 0;
}

div.ant-modal-body div.radio-frequency-item,
div.ant-modal-body div.modal-frequency-section {
  margin-bottom: 20px;
}

div.ant-modal-body div.modal-frequency-section.row,
div.ant-modal-body div.row.radio-frequency-item {
  align-items: flex-end;
}

/* .fc-theme-standard td,
.fc-theme-standard th {
  border: none;
  border-top: 1px solid var(--lineGray);
} */

.modal-schedules-section span.ant-tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px 2px 10px;
}

.modal-schedules-section
  span.ant-tag
  span.anticon.anticon-close.ant-tag-close-icon
  svg {
  height: 15px;
  margin-left: 10px;
}

a.fc-col-header-cell-cushion {
  color: var(--white);
}

a.fc-daygrid-day-number {
  color: var(--darkGray);
}

button.button-color-picker {
  border: none;
  height: 15px;
  border-radius: 10px;
}

div.ant-modal-content > div.ant-modal-body > form > div.participants-tags {
  margin-bottom: 20px;
  margin-left: 38px;
}

.checkbox-select-all-schedules {
  margin-left: 10px;
  margin-top: 10px;
}

.checkbox-select-all-schedules span.ant-checkbox.ant-checkbox-checked > span {
  border-color: var(--orange);
  background-color: var(--orange);
}

.divider-select-all-schedules {
  margin-top: 10px;
  margin-bottom: 10px;
}

button.ant-btn.schedules-clear span {
  color: var(--orange);
}

button.ant-btn.schedules-clear {
  padding: 0;
  margin-left: 20px;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center;
}

/* *********************************************** HEADER ****************************************** */
header.header {
  background-color: var(--orange);
  padding: 0 15px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.avatar {
  height: 70px;
  width: 70px;
  background-color: var(--darkGray);
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar img {
  height: 70px;
  width: 70px;
  border-radius: 35px;
}

header div.avatar p {
  color: var(--white);
  font-size: var(--sizeTitle);
  margin: 0;
}

/* @media(max-width: 768px){
  .saudacao{
    font-size: var(--sizeTitleResize);
  }
  .avatar{
    height: 44px;
    width: 44px;
    border-radius: 22px;
  }

  header div.avatar p {
    color: var(--white);
    font-size: var(--sizeTitleResize);
    margin: 0;
  }
} */

@media (max-width: 564px) {
  .saudacao {
    font-size: var(--sizeSubtitle);
  }
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }

  header div.avatar p {
    color: var(--white);
    font-size: var(--sizeSubtitle);
    margin: 0;
  }
}

/* *********************************************** APP - GERAL ****************************************** */
.app .page {
  margin: 0;
  padding-right: 8px;
  display: grid;
  grid-template-columns: var(--aside-width) 1fr;
  grid-template-rows: var(--header-height) 1fr;
  grid-template-areas: "logo header" "menu content";
  background-color: #f5f5f5;
  min-height: 100vh;
}

.app .login {
  margin: 0;
  background-color: var(--orange);
  display: flex;
  justify-content: center;
  align-items: center;
}

aside.logo {
  grid-area: logo;
}

header.header {
  grid-area: header;
}

aside.menu-area {
  grid-area: menu;
}

div.content {
  grid-area: content;
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
p,
textarea,
input,
.ant-modal-title {
  color: var(--darkGray);
}

button.ant-btn span {
  color: var(--darkGray);
}

button.ant-btn.ant-btn-primary span {
  color: var(--white);
}

@media (max-width: 768px) {
  .app .page {
    grid-template-rows: 1fr var(--header-height) 14fr;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "menu menu" "logo header" "content content";
  }
  .app .page.reading,
  .app .page.edicao {
    grid-template-rows: 1fr var(--header-height) 64fr;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "menu menu" "logo header" "content content";
  }

  .app .page.tutor,
  .app .page.parents,
  .app .page.student {
    grid-template-rows: 1fr var(--header-height) 44fr;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "menu menu" "logo header" "content content";
  }
}

.ant-btn,
.ant-picker-input > input {
  color: var(--darkGray);
}

*[class^="ant-"],
*[class^="ant-"]::before,
*[class^="ant-"]::after {
  box-sizing: content-box;
}

/* ------ Radio ------ */

.ant-radio-wrapper {
  color: var(--darkGray);
}

.ant-radio-inner::after {
  background-color: var(--orange);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--lineGray);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--lineGray);
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

/* ******************************************** EXCEÇÕES ****************************************** */
p.saudacao {
  font-size: var(--sizeTitle);
  color: var(--white) !important;
}

button.ant-btn.ant-btn-link.ant-btn-lg span {
  color: var(--white);
}

button.ant-btn.ant-btn-link.forgotPassword span {
  color: var(--white);
}

div.ant-modal-footer button.ant-btn.ant-btn-primary span {
  color: var(--white);
}

li.ant-picker-ok button.ant-btn.ant-btn-primary.ant-btn-sm span {
  color: var(--white);
}
