
aside.logo {
  background-color: var(--orange);
  display: flex;
  justify-content: center;
  align-items: center;
}

aside.logo img {
  padding: 10px;
  width: 90%;
}

/* @media(max-width: 768px){
  aside.logo img {
    width: 90%;
  }
} */

@media(max-width: 564px){
  aside.logo img {
    width: 80%;
  }
}