aside.menu-area{
  background-color: var(--darkGray);
  box-shadow: var(--shadow)
}
.logout,
.menu a{
  display: block;
  text-decoration: none;
  color: var(--inactiveItem);
  padding: 10px 0 5px 10px;
}

.menu a:hover{
  /* background: var(--activeField); */
  color: var(--inactiveItem); 
  text-decoration: none;
}

.menu a.active{
  background: var(--activeField);
  color: var(--white);
  text-decoration: none;
}

div .card,
.menu .card{
  display: block;
  background-color: var(--darkGray);
  text-decoration: none;
  color: var(--inactiveItem);
  padding: 10px 0 5px 10px;
  border-width: 0;
}

.menu .card:hover{
  /* background: var(--activeField);*/
  color: var(--inactiveItem); 
  text-decoration: none;
  cursor: pointer;
}

.menu .card.active{
  background: var(--activeField);
  color: var(--white);
  text-decoration: none;
}

.menu .collapse.active a{
  background: var(--subItem);
  color: var(--white);
  text-decoration: none;
}

.menu .collapse.subitem a{
  margin-left: 15px;
  padding-bottom: 8px;
}

.menu .icon{
  margin-right: 15px;
  padding-bottom: 8px;
}

.menu span{
  font-size: var(--sizeSmall);
}

button.logout-button {
  display: block;
  background-color: var(--darkGray);
  text-decoration: none;
  color: var(--inactiveItem);
  padding: 10px 0 5px 10px;
  border: none;
}

button.logout-button:focus {
  border: none;
}